import {EagleApiInterface, EagleModuleApiInterface} from 'kernel/api/api'

export default (api: EagleApiInterface) => {
  api.collection.bannerPhotoApi = <EagleModuleApiInterface>{
    create: (params = {}) => api.request({
      url: `/api/admin/banner-photo`,
      type: 'post',
      params,
    }),
    update: (id: string, params = {}) => api.request({
      url: `/api/admin/banner-photo/${id}/update`,
      type: 'put',
      params,
    }),
    delete: (id: string) => api.request({
      url: `/api/admin/banner-photo/${id}`,
      type: 'delete',
    }),
  }

  api.collection.bannerApi = <EagleModuleApiInterface>{
    read: (id: string) => api.request({
      url: `/api/admin/banner/${id}`,
      type: 'get',
    }),
    index: (params = {}) => api.request({
      url: `/api/admin/banner/index`,
      type: 'get',
      params,
    }),
    create: (params = {}) => api.request({
      url: `/api/admin/banner`,
      type: 'post',
      params,
    }),
    update: (id: string, params = {}) => api.request({
      url: `/api/admin/banner/${id}/update`,
      type: 'put',
      params,
    }),
    patch: (id: string, column: string, value: any) => api.request({
      url: `/api/admin/banner/${id}`,
      type: 'patch',
      params: {
        column,
        value,
      },
    }),
    delete: (id: string) => api.request({
      url: `/api/admin/banner/${id}`,
      type: 'delete',
    }),
    deleteBannerItem: (id: string) => api.request({
      url: `/api/admin/banner/item/${id}`,
      type: 'delete',
    }),
    batch: (targets: string[], column: string, value: any) => api.request({
      url: `/api/admin/banner/batch`,
      type: 'patch',
      params: {
        ids: targets,
        column,
        value,
      },
    }),
    batchDelete: (targets: string[]) => api.request({
      url: `/api/admin/banner/batch`,
      type: 'delete',
      params: { ids: targets },
    }),
  }
}
